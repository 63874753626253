import React, { useEffect, useState } from "react";

interface ProgressProps {
  fille: number;
  garcon: number;
  onComplete?: () => void;
}

const ProgressBar: React.FC<ProgressProps> = ({ fille, garcon, onComplete }) => {
  const [progress, setProgress] = useState(0); // État pour suivre la progression totale

  useEffect(() => {
    let currentProgress = 0;

    // Fonction récursive pour mettre à jour la progression
    const updateProgress = (intervalTime: number) => {
      if (currentProgress < 100) {
        currentProgress += 0.5;
        setProgress(currentProgress);

        // Calculer le nouvel intervalle (réduire progressivement)
        const newInterval = Math.max(intervalTime - 1, 50); // On ne descend pas en dessous de 20ms

        // Utiliser setTimeout pour ajuster dynamiquement la vitesse
        setTimeout(() => updateProgress(newInterval), newInterval);
      }
    };

    // Démarrer l'animation avec un intervalle initial de 100ms
    updateProgress(150);

    // Cleanup lors du démontage du composant
    return () => {
      currentProgress = 100; // On stoppe si le composant est démonté
    };
  }, []);

  useEffect(() => {
    if (progress === (fille > garcon ? fille : garcon) && onComplete) {
      onComplete(); // Appeler le callback une fois la progression terminée
    }
  }, [progress, onComplete]);

  return (
    <div style={{ width: "100%", height: "150px", position: "relative", backgroundColor: "#eee", borderRadius: "15px", overflow: "hidden" }}>
      {/* Barre rose (fille) - commence de 0% */}
      <div
        className="text-4xl bg-pink-300"
        style={{
          width: `${Math.min(progress, fille)}%`,
          //backgroundColor: "pink",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          transition: "width 0.15s ease-in-out",
          display: "flex", // Pour centrer le texte
          justifyContent: "center",
          alignItems: "center",
          color: "white", // Couleur du texte
          fontWeight: "bold", // Texte en gras
        }}
      >{progress <= fille ? `${Math.floor(progress)}%` : !isNaN(fille) && `${fille}%`}</div>

      {/* Barre bleue (garçon) - commence de 100% et diminue */}
      <div
        className="bg-blue-300 text-4xl"
        style={{
          width: `${Math.min(progress, garcon)}%`, // Réduire de 100% jusqu'à garcon%
          //backgroundColor: "lightblue",
          height: "100%",
          position: "absolute",
          right: 0, // Pour que la barre bleue commence à droite
          top: 0,
          transition: "width 0.15s ease-in-out",
          display: "flex", // Pour centrer le texte
          justifyContent: "center",
          alignItems: "center",
          color: "white", // Couleur du texte
          fontWeight: "bold", // Texte en gras
        }}
        >{progress <= garcon ? `${Math.floor(progress)}%` : !isNaN(garcon) && `${garcon}%`}</div>
    </div>
  );
};

export default ProgressBar;
