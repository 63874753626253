import React, { useEffect, useState } from 'react';

interface Data {
  ID: number,
  DATE: string,
  REFERENCE: string,
  NOM_PRENOM: string,
  SEXE: number,
  PRENOM_GARCON: string,
  PRENOM_FILLE: string,
  TERME: string,
  POIDS: number,
  TAILLE: number
}

const Manage: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [dataAnalaysed, setDataAnalaysed] = useState({"SEXEM": 0, "SEXEF": 0});
  const [error, setError] = useState<string | null>(null);

  const formatDateToLocal = (isoDateString: string, timeZone: string): string => {
    const date = new Date(isoDateString);
  
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timeZone,
      hour12: false
    };
  
    // Format the date using Intl.DateTimeFormat
    const formatter = new Intl.DateTimeFormat('fr-FR', options);
    const parts = formatter.formatToParts(date);
    
    // Extract parts
    const day = parts.find(part => part.type === 'day')?.value || '';
    const month = parts.find(part => part.type === 'month')?.value || '';
    const year = parts.find(part => part.type === 'year')?.value || '';
    const hour = parts.find(part => part.type === 'hour')?.value || '';
    const minute = parts.find(part => part.type === 'minute')?.value || '';
    const second = parts.find(part => part.type === 'second')?.value || '';
  
    return `${day}/${month}/${year} à ${hour}:${minute}:${second}`;
  };

  const getAllData = async () => {
    const response = await fetch('https://gr-api.bregand.fr/getalldata', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
        setError(response.status.toString());
        return;
    }

    const datas = await response.json();

    setData(datas);
    analyseData(datas);
  }

  const analyseData = (data: Data[]) => {
    let sexeM = 0, sexeF = 0;
    data.forEach(item => {
      (item.SEXE ? sexeM++ : sexeF++);
    });

    setDataAnalaysed({"SEXEM": sexeM, "SEXEF": sexeF});
  }

  useEffect(() => {
    getAllData();
  }, []);

  const garcon = Math.round(dataAnalaysed.SEXEM * 100 / (dataAnalaysed.SEXEM + dataAnalaysed.SEXEF));
  const fille = Math.round(dataAnalaysed.SEXEF * 100 / (dataAnalaysed.SEXEM + dataAnalaysed.SEXEF));

  return (
    <div> {error}
      <h2 className="text-4xl mb-3">GR MANAGER</h2>
      <div className="uppercase">Garçon {garcon}% / Fille {fille}%</div>
      <div>{data.map((item, index) => (
        <div key={index} className="my-3 rounded bg-slate-200 px-2 py-3">
          <div className="text-xl font-bold truncate">[{item.REFERENCE}] {item.NOM_PRENOM}</div>
          <div>Sexe: {item.SEXE ? "garçon" : "fille"}</div>
          <div>Prénom {item.SEXE ? "garçon: " + item.PRENOM_GARCON : "fille: " + item.PRENOM_FILLE}</div>
          <div>Prénom {!item.SEXE ? "garçon: " + item.PRENOM_GARCON : "fille: " + item.PRENOM_FILLE}</div>
          <div>Terme: {formatDateToLocal(item.TERME, "Europe/Paris").split(" ")[0]}</div>
          <div>Poids: {item.POIDS} g</div>
          <div>Taille: {item.TAILLE} cm</div>
          <div className="text-sm truncate">{formatDateToLocal(item.DATE, "Europe/Paris")}</div>
        </div>
      ))}</div>
    </div>
  );
};

export default Manage;
