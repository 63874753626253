import React from 'react';
import { useParams } from 'react-router-dom';

const Index: React.FC = () => {
  const { reference } = useParams<{ reference: string }>();

  return (
    <div className="grid h-screen place-items-center">
      <a href={"/vote/" + (reference?reference:"null")} className="animate-bounce bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-600 font-medium hover:bg-gradient-to-br hover:shadow-gray-200 rounded-lg text px-5 py-2.5 text-center me-2 mb-2">
        C'est parti !
      </a>
    </div>
  );
};

export default Index;
