import React from "react";
import "./PrenomDisplay.css";

// Type pour les prénoms
type PrenomGenre = "fille" | "garcon";

type Prenom = {
  nom: string;
  genre: PrenomGenre;
};

type Props = {
  fille: string[];
  garcon: string[];
};

const PrenomDisplay: React.FC<Props> = ({ fille, garcon }) => {
  const getRandomFontSize = () => {
    const sizes = ["3rem", "3.5rem", "4rem", "4.5rem", "5rem"];
    return sizes[Math.floor(Math.random() * sizes.length)];
  };

  return (
    <div className="flex w-screen h-screen">
      <div className="column h-screen content-center">
        {fille.map((nom, index) => (
          <span
            key={index}
            style={{ fontSize: getRandomFontSize() }}
            className="text-pink-300"
          >
            {nom}
          </span>
        ))}
      </div>
      <div className="column h-screen content-center">
        {garcon.map((nom, index) => (
          <span
            key={index}
            style={{ fontSize: getRandomFontSize() }}
            className="text-blue-300"
          >
            {nom}
          </span>
        ))}
      </div>
    </div>
  );
};

export default PrenomDisplay;
