import React, { useState } from 'react';
import { Datepicker } from 'flowbite';
import { useParams } from 'react-router-dom';

const Vote: React.FC = () => {
  const { reference } = useParams<{ reference: string }>();
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState<string>();
  const [sexe, setSexe] = useState<boolean | null>(null);
  const [nameSexe, setNameSexe] = useState<string>();
  const [nameBis, setNameBis] = useState<string>();
  const [poids, setPoids] = useState<number>(3200);
  const [taille, setTaille] = useState<number>(50);
  const [buttonDisabled, disableButton] = useState<boolean>(true);
  const [datepicker, setDatePicker] = useState<Datepicker>();
  
  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hName = event.target.value.trim();
    setName(hName);
    if(hName.length > 5) {
      disableButton(false);
    }
    else {
      disableButton(true);
    }
  };

  const validName = () => {
    if(name && name.length > 5) {
      const elementH = document.getElementById('guest');
      const element = document.getElementById('sexe');
      if (elementH && element) {
        elementH.classList.toggle('hidden');
        element.classList.toggle('hidden');
        disableButton(true);
      }
    }
  }

  const changeBgColor = (color : number) => {
    if(color == 1)
    {
      document.body.classList.remove('bg-pink-100');
      document.body.classList.add('bg-blue-100');
      setSexe(true);
    }
    else
    {
      document.body.classList.remove('bg-blue-100');
      document.body.classList.add('bg-pink-100');
      setSexe(false);
    }
    disableButton(false);
  }

  const validSexe = () => {
    if(sexe != null) {
      const elementH = document.getElementById('sexe');
      const element = document.getElementById('name');
      if (elementH && element) {
        elementH.classList.toggle('hidden');
        element.classList.toggle('hidden');
        disableButton(true);
      }
    }
  }

  const handleNameSexe = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hName = event.target.value.trim();
    setNameSexe(hName);
    if(hName.length > 2) {
      disableButton(false);
    }
    else {
      disableButton(true);
    }
  };

  const handleNameBis = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hName = event.target.value.trim();
    setNameBis(hName);
    if(hName.length > 2) {
      disableButton(false);

      const $datepicker: HTMLInputElement = document.getElementById('datepicker-inline') as HTMLInputElement;
      const options = {
        locale: {daysMin: ["Lu", "Lu", "Lu", "Lu", "Lu", "Lu", "Lu"]},
        defaultDatepickerId: "datepicker-inline",
        autohide: false,
        format: 'mm/dd/yyyy',
        maxDate: null,
        minDate: null,
        orientation: 'bottom',
        buttons: false,
        autoSelectToday: 0,
        title: null,
        rangePicker: false,
        onShow: () => {},
        onHide: () => {},
      };
      const dp = new Datepicker($datepicker, options);
      dp.setDate('03/03/2025');
      setDatePicker(dp);
    }
    else {
      disableButton(true);
    }
  };

  const validNameSexe = () => {
    if(nameSexe && nameSexe.length > 2) {
      const elementH = document.getElementById('name');
      const element = document.getElementById('namebis');
      if (elementH && element) {
        elementH.classList.toggle('hidden');
        element.classList.toggle('hidden');
        disableButton(true);
      }
    }
  }

  const validNameBis = () => {
    if(nameSexe && nameSexe.length > 2) {
      const elementH = document.getElementById('namebis');
      const element = document.getElementById('terme');
      if (elementH && element) {
        elementH.classList.toggle('hidden');
        element.classList.toggle('hidden');
        //disableButton(true);
      }
    }
  }

  const validDatePicker = () => {
    const elementH = document.getElementById('terme');
    const element = document.getElementById('poids');
    if (elementH && element) {
      elementH.classList.toggle('hidden');
      element.classList.toggle('hidden');
      disableButton(true);
    }
  }

  const changePoids = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hpoids = event.target.valueAsNumber;
    setPoids(hpoids);
    if(hpoids > 1000 && hpoids < 7000) {
      disableButton(false);
    }
    else {
      disableButton(true);
    }
  };

  const validPoids = () => {
    const elementH = document.getElementById('poids');
    const element = document.getElementById('taille');
    if (elementH && element) {
      elementH.classList.toggle('hidden');
      element.classList.toggle('hidden');
      disableButton(true);
    }
  }

  const changeTaille = (event: React.ChangeEvent<HTMLInputElement>) => {
    const htaille = event.target.valueAsNumber;
    setTaille(htaille);
    if(htaille > 30 && htaille < 80) {
      disableButton(false);
    }
    else {
      disableButton(true);
    }
  };

  const validTaille = () => {
    const elementH = document.getElementById('taille');
    const element = document.getElementById('fin');
    if (elementH && element) {
      elementH.classList.toggle('hidden');
      element.classList.toggle('hidden');
      disableButton(true);
      sendInformations();
    }
  }

  const sendInformations = async () => {
    let terme = "";
    if(datepicker) {
      const date = new Date(datepicker.getDate().toString());
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      terme = `${year}-${month}-${day}`;
    }
    const exportData = {
      "reference": reference,
      "nom_prenom": name,
      "sexe": sexe,
      "prenom_garcon": (sexe?nameSexe:nameBis),
      "prenom_fille": (!sexe?nameSexe:nameBis),
      "terme": terme,
      "poids": poids,
      "taille": taille
    };

    try {
      const response = await fetch('https://gr-api.bregand.fr/export', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(exportData),
      });

      if (!response.ok) {
          setError(response.status.toString());
          return;
      }

      window.history.pushState({}, '', "/" + reference);
    } catch (error) {
        console.error('Erreur:', error);
        setError("Erreur fatale! Voir la console..");
    }
  }

  return (
    <div className="grid h-screen place-items-center">
      <div id="guest" className="font-medium">
        <div className="font-bold text-2xl mb-1">Hello 👋</div>
        <div className="mb-3">Comment t'appelles-tu ?</div>
        <div className="">
          <input className="w-full border border-1 rounded p-2" placeholder="NOM Prénom" onChange={handleName}/>
          <div>
            <button className={"w-full bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-600 font-medium rounded-lg text py-2 text-center mt-2 " + (buttonDisabled ? "opacity-25" : "hover:bg-gradient-to-br hover:shadow-gray-200")} disabled={buttonDisabled} onClick={validName}>Je valide !</button>
          </div>
        </div>
      </div>

      <div id="sexe" className="font-medium hidden">
        <div className="font-bold text-2xl mb-1">Ton avis 🤔</div>
        <div className="mb-3">Plutôt fille ou garçon ?</div>
        <div className="grid grid-cols-2 gap-2">
          <button className={"bg-pink-200 rounded font-medium p-1" + (sexe === null ? " hover:bg-pink-300" : sexe && " opacity-50 hover:bg-pink-300")} onClick={() => {changeBgColor(0)}}>Fille</button>
          <button className={"bg-blue-200 rounded font-medium p-1" + (sexe === null ? " hover:bg-blue-300" : !sexe && " opacity-50 hover:bg-blue-300")} onClick={() => {changeBgColor(1)}}>Garçon</button>
        </div>
        <div>
          <button className={"w-full bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-600 font-medium rounded-lg text py-2 text-center mt-2 " + (buttonDisabled ? "opacity-25" : "hover:bg-gradient-to-br hover:shadow-gray-200")} disabled={buttonDisabled} onClick={validSexe}>Je valide !</button>
        </div>
      </div>

      <div id="name" className="font-medium hidden">
        <div className="font-bold text-2xl mb-1">{sexe?"Un garçon 👦":"Une fille 👧"} ?</div>
        <div className="mb-3">Une idée sur le prénom ?</div>
        <div className="">
          <input className="w-full border border-1 rounded p-2" placeholder={"Prénom " + (sexe?"garçon":"fille")} onChange={handleNameSexe}/>
          <div>
            <button className={"w-full bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-600 font-medium rounded-lg text py-2 text-center mt-2 " + (buttonDisabled ? "opacity-25" : "hover:bg-gradient-to-br hover:shadow-gray-200")} disabled={buttonDisabled} onClick={validNameSexe}>Je valide !</button>
          </div>
        </div>
      </div>
      
      <div id="namebis" className="font-medium hidden">
        <div className="font-bold text-2xl mb-1">Et si c'est {!sexe?"un garçon 👦":"une fille 👧"} ?</div>
        <div className="mb-3">Une idée sur le prénom ?</div>
        <div className="">
          <input className="w-full border border-1 rounded p-2" placeholder={"Prénom " + (!sexe?"garçon":"fille")} onChange={handleNameBis}/>
          <div>
            <button className={"w-full bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-600 font-medium rounded-lg text py-2 text-center mt-2 " + (buttonDisabled ? "opacity-25" : "hover:bg-gradient-to-br hover:shadow-gray-200")} disabled={buttonDisabled} onClick={validNameBis}>Je valide !</button>
          </div>
        </div>
       </div>

       <div id="terme" className="font-medium hidden">
        <div className="font-bold text-2xl mb-1">Et pour la date ? Une idée ? ⏳</div>
        <div className="mb-3">Le terme est prévu pour le 03/03/2025</div>
        <div>
          <div id="datepicker-inline" className="flex justify-center"></div>
          <div>
            <button className={"w-full bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-600 font-medium rounded-lg text py-2 text-center mt-3 " + (buttonDisabled ? "opacity-25" : "hover:bg-gradient-to-br hover:shadow-gray-200")} disabled={buttonDisabled} onClick={validDatePicker}>Je valide !</button>
          </div>
        </div>
       </div>

       <div id="poids" className="font-medium hidden">
        <div className="font-bold text-2xl mb-1">Le poids ⚖</div>
        <div className="mb-3">Une idée sur le poids ?</div>
        <div className="">
        <div className="relative flex items-center max-w-[11rem]">
          <input type="number" pattern="\d*" className="h-11 font-medium text-center w-full border border-1 rounded p-2 block w-full pb-6" placeholder="3200" onChange={changePoids} />
          <div className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
              <span>g</span>
          </div>
      </div>
          <div>
            <button className={"w-full bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-600 font-medium rounded-lg text py-2 text-center mt-2 " + (buttonDisabled ? "opacity-25" : "hover:bg-gradient-to-br hover:shadow-gray-200")} disabled={buttonDisabled} onClick={validPoids}>Je valide !</button>
          </div>
        </div>
      </div>

      <div id="taille" className="font-medium hidden">
        <div className="font-bold text-2xl mb-1">La taille 📏</div>
        <div className="mb-3">Une idée sur la taille ?</div>
        <div className="">
        <div className="relative flex items-center max-w-[11rem]">
          <input type="number" pattern="\d*" className="h-11 font-medium text-center w-full border border-1 rounded p-2 block w-full pb-6" placeholder="50" onChange={changeTaille} />
          <div className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
              <span>cm</span>
          </div>
      </div>
          <div>
            <button className={"w-full bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-600 font-medium rounded-lg text py-2 text-center mt-2 " + (buttonDisabled ? "opacity-25" : "hover:bg-gradient-to-br hover:shadow-gray-200")} disabled={buttonDisabled} onClick={validTaille}>Je valide !</button>
          </div>
        </div>
      </div>

       <div id="fin" className="font-medium hidden p-3">
        <div className="font-bold text-2xl mb-1">Merci ❤️</div>
        {error != null ?
        <div>❌ Erreur <kbd className="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">{error}</kbd></div>
        :
        <div>
          <div className="mb-3">Tes réponses ont bien été enregistrées</div>
          <div className="mb-3">Rendez-vous dans quelques mois pour savoir si tu avais raison 🐣</div>
        </div>
        }
       </div>
    </div>
  );
};

export default Vote;
