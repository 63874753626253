import React, { useEffect, useRef, useState } from 'react';
import ProgressBar from './ProgressBar';
import { useParams } from 'react-router-dom';
import Rebour from './Rebour';
import PrenomDisplay from './Prenoms';

interface Data {
  ID: number,
  DATE: string,
  REFERENCE: string,
  NOM_PRENOM: string,
  SEXE: number,
  PRENOM_GARCON: string,
  PRENOM_FILLE: string,
  TERME: string,
  POIDS: number,
  TAILLE: number
}

const Reveal: React.FC = () => {
  const { filter } = useParams();
  const [filters, setFilters] = useState<string[]>([]);
  const [data, setData] = useState<Data[]>([]);
  const [dataAnalaysed, setDataAnalaysed] = useState({"SEXEM": 0, "SEXEF": 0});
  const [error, setError] = useState<string | null>(null);
  const [go, setGo] = useState<boolean>(false);
  const [pBar, setPBar] = useState<boolean>(false);
  const [revealation, setRevealation] = useState<boolean>(false);
  const [prenoms, setPrenoms] = useState<boolean>(false);
  //const soundRef = useRef(new Audio('/bango.mp3'));
  const soundRef2 = useRef(new Audio('/end.mp3'));
  const soundRef3 = useRef(new Audio('/tuut.mp3'));
  const [garcon, setGarcon] = useState<string[]>([]); 
  const [fille, setFille] = useState<string[]>([]); 

  const getAllData = async () => {
    const response = await fetch('https://gr-api.bregand.fr/getalldata', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
        setError(response.status.toString());
        return;
    }

    const datas = await response.json();

    setData(datas);
    analyseData(datas);
  }

  const analyseData = (data: Data[]) => {
    let sexeM = 0, sexeF = 0;
    let garconsArray = new Set<string>();
    let fillesArray = new Set<string>();

    data.forEach(item => {
      if (filters.includes(item.REFERENCE)) {
        if (item.SEXE) {
          sexeM++;
        } else {
          sexeF++;
        }
      }
      garconsArray.add(item.PRENOM_GARCON);
      fillesArray.add(item.PRENOM_FILLE);
    });

    setGarcon(Array.from(garconsArray));
    setFille(Array.from(fillesArray));
    setDataAnalaysed({"SEXEM": sexeM, "SEXEF": sexeF});
  }

  const setFiltersParams = () => {
    const newFilters = filter?.split(',') || [];
    setFilters(newFilters);
  }

 /*const fadeOutSound = () => {
    const sound = soundRef.current;
    let volume = sound.volume;

    const fadeInterval = setInterval(() => {
      if (volume > 0.05) {
        volume -= 0.05;  // Decrease volume gradually
        sound.volume = volume;
      } else {
        clearInterval(fadeInterval);  // Stop the interval when volume reaches 0
        sound.pause();
        sound.volume = 0.5;  // Reset volume for future plays
      }
    }, 50);  // Adjust the speed of fading (100ms between steps)
  };*/

  const tuut = () => {
    const sound = soundRef3.current;
    sound.play();
  
    // Arrête la musique après 1 seconde
    setTimeout(() => {
      sound.pause();
      sound.currentTime = 0; // Remet la musique au début, si tu veux.
    }, 2500); // 1000 millisecondes = 1 seconde
  }

  useEffect(() => {
    setFiltersParams();
  }, []);

  useEffect(() => {
    getAllData();
  }, [filters]);

  useEffect(() => {
    analyseData(data);
  }, [data, filters]);

  return (
    <div onClick={() => {pBar && setPrenoms(true)}} className="flex items-center justify-center h-screen"> 
        {go ?
          <div className="w-full h-full">
            <div className={`absolute w-full left-0 top-0 text-center content-center h-full`}  style={{opacity: revealation ? 1 : 0, transition: 'opacity 1s ease-in-out'}}>
              {revealation && <Rebour onComplete={tuut} />}
            </div>
            <div className={`absolute top-0 left-0 container h-full w-full ${prenoms && 'z-10'}`}  style={{opacity: prenoms && !revealation ? 1 : 0, transition: 'opacity 1s ease-in-out'}} onClick={() => setRevealation(true)}>
              {prenoms && !revealation && <PrenomDisplay fille={fille} garcon={garcon} />}
            </div>
            <div style={{opacity: prenoms || revealation ? 0 : 1, transition: 'opacity 1s ease-in-out'}} className={`absolute top-0 container h-full content-center`} >
              <div><ProgressBar garcon={Math.round(dataAnalaysed.SEXEM * 100 / (dataAnalaysed.SEXEM + dataAnalaysed.SEXEF))} fille={Math.round(dataAnalaysed.SEXEF * 100 / (dataAnalaysed.SEXEM + dataAnalaysed.SEXEF))} onComplete={() => {setPBar(true); }} /></div>
              <div style={{opacity: pBar ? 1 : 0, transition: 'opacity 1s ease-in-out'}} className="flex">
                <div className="w-1/2 text-6xl m-3 text-center text-pink-300">
                  {data.map((item, index) => (
                    item.SEXE === 0 && filters.includes(item.REFERENCE) && <div key={item.ID}>{item.NOM_PRENOM}</div>
                  ))}
                </div>
                <div className="w-1/2 text-6xl m-3 text-center text-blue-300">
                  {data.map((item, index) => (
                    item.SEXE === 1 && filters.includes(item.REFERENCE) && <div key={item.ID}>{item.NOM_PRENOM}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        :
          <div className='h-screen content-center'>
            <button className="animate-bounce bg-gradient-to-r from-blue-200 to-pink-200 shadow-lg shadow-gray-200/50 text-gray-50 font-medium hover:bg-gradient-to-br hover:shadow-gray-200 rounded-2xl text-9xl px-10 py-10 text-center me-2 mb-2" onClick={() => {setGo(true); soundRef2.current.play()}}>C'est parti !</button>
          </div>
        }
    </div>
  );
};

export default Reveal;
